import React, { Component } from "react"
import "./AboutLogos.css"
import Flip from "react-reveal/Flip"

const logos = {
  react:
    "https://cdn4.iconfinder.com/data/icons/logos-3/600/React.js_logo-512.png",
  redux: "https://raw.githubusercontent.com/reduxjs/redux/master/logo/logo.png",
  nodejs: "http://pluspng.com/img-png/nodejs-logo-png--435.png",
  mongo:
    "https://cms-assets.tutsplus.com/uploads/users/1116/posts/24835/preview_image/mongodb-logo.png",
  docker:
    "https://cdn3.iconfinder.com/data/icons/social-media-2169/24/social_media_social_media_logo_docker-512.png",
}
class AboutLogos extends Component {
  renderLogos() {
    return Object.keys(logos).map(logo => {
      let curr = logos[logo]
      return (
        <div key={logo} className="logo">
          <img src={curr} alt={logo} />
        </div>
      )
    })
  }

  render() {
    return (
      <Flip bottom delay={1500}>
        <div className="logo-container">{this.renderLogos()}</div>
      </Flip>
    )
  }
}

export default AboutLogos
