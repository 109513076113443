import React, { Component } from "react";
import "./Project.css";

export default class Project extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current: ""
    };
  }
  render() {
    return (
      <div className='project-container'>
        <div className='project-row'>
          <div className='project-wheel'>
            <div
              className='project-wrapper'
              onMouseEnter={() =>
                this.setState({ current: "Sample Project 1" })
              }
              onMouseLeave={() => this.setState({ current: "" })}></div>
            <div
              className='project-wrapper'
              onMouseEnter={() =>
                this.setState({ current: "Sample Project 2" })
              }
              onMouseLeave={() => this.setState({ current: "" })}></div>
            <div
              className='project-wrapper'
              onMouseEnter={() =>
                this.setState({ current: "Sample Project 3" })
              }
              onMouseLeave={() => this.setState({ current: "" })}></div>
          </div>

          <div className='project-name'>
            <h1>{this.state.current}</h1>
          </div>
        </div>
      </div>
    );
  }
}
