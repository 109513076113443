import React from "react";
import "./About.css";

import Slide from "react-reveal/Slide";
import Fade from "react-reveal/Fade";

import Logos from "../AboutLogos/AboutLogos";

function About() {
  return (
    <div className='background-container'>
      <div className='about-container'>
        <Slide left>
          <div className='about-title'>
            <h1>hey</h1>
          </div>
        </Slide>
        <Fade right delay={300} duration={1500}>
          <div className='about-body'>
            <p>
              My name is Daniel and I am a{" "}
              <span className='highlight'>web developer</span>. Using industry
              trusted technology, I create solutions for my clients that ensure{" "}
              <span className='under'>performance</span> and{" "}
              <span className='under'>security</span>. With equal focus on
              design and development, all functionality fits it's form.
            </p>
          </div>
        </Fade>
      </div>
      <Logos />
    </div>
  );
}

export default About;
