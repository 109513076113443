import React from "react";
import "./Hero.css";
import Jump from "react-reveal/Jump";
import Fade from "react-reveal/Fade";

function Hero() {
  return (
    <div className='background-container'>
      <div className='hero-container'>
        <div className='hero-main'>
          <Jump delay={2000}>
            <h1>db</h1>
          </Jump>
        </div>
        <div className='hero-secondary'>
          <div className='hero-secondary-child1'>
            <Fade top>
              <p>web</p>
            </Fade>
            <Fade top delay={500}>
              <p>dev</p>
            </Fade>
          </div>
          <div className='hero-secondary-child2'>
            <Fade top delay={800}>
              <p>elo</p>
            </Fade>
            <Fade top delay={1200}>
              <p>per</p>
            </Fade>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hero;
